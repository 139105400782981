/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Mobile zoom adjustment */
@media screen and (max-width: 767px) {
    html {
        font-size: 90%; /* Reduce to 90% of default font size on mobile */
    }
}

.logo {
    width: 100px;
    height: auto;
    display: block;
    margin: 0 auto 20px;
}


/* Ensure the body and html take the full viewport height */
html, body {
    height: 100%;
    font-family: "Arial", sans-serif;
    line-height: 1.6;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

/* Light and Dark Modes - Updated with charcoal grey */
body.dark {
    background: linear-gradient(180deg, #2d3039, #222530);
    color: #e8e8e8;
}

body.light {
    background: linear-gradient(180deg, #f7f7f7, #eaeaea);
    color: #333;
}

/* Remove background element after body */
body::after {
    display: none; /* Remove this element entirely */
}

/* Main Container */
.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Mobile container adjustment */
@media screen and (max-width: 767px) {
    .container {
        padding: 0 15px; /* Slightly reduce padding on mobile */
        width: 100%;
    }
}

main {
    flex-grow: 1; /* Pushes the footer to the bottom when content is insufficient */
}

/* Hero Section */
.hero {
    text-align: center;
    padding: 60px 20px;
    margin-bottom: 40px;
}

/* Mobile hero adjustments */
@media screen and (max-width: 767px) {
    .hero {
        padding: 40px 15px;
        margin-bottom: 30px;
    }
}

.hero-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

/* Mobile text size adjustments */
@media screen and (max-width: 767px) {
    .hero-content h1 {
        font-size: 2.5rem; /* Slightly smaller on mobile */
    }

    .hero-content p {
        font-size: 1.1rem;
    }
}

.hero-content h1,
.hero-content p {
    color: inherit;
}

/* Hero Section Buttons */
.button-group {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between buttons */
}

/* Mobile button adjustments */
@media screen and (max-width: 767px) {
    .button-group {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .cta-button {
        width: 90%; /* Make buttons wider on mobile */
    }
}

.cta-button {
    background: #5865f2;
    border: none;
    color: white;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: transform 0.2s ease, background 0.3s ease;
}

.cta-button:hover {
    background: #404eed;
    transform: translateY(-2px);
}

.cta-button.secondary {
    background: #404eed; /* Same bright blue for consistency */
}

.cta-button.secondary:hover {
    background: #404eed; /* Matches hover effect of primary button */
}

/* Popup Modal */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
    background: inherit;
    color: inherit;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

/* Overlay to fade out the page */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    z-index: 999; /* Layer above the content */
    opacity: 1;
    transition: opacity 0.3s ease;
}

/* Why Us Section */
.why-us {
    text-align: center;
    margin-bottom: 40px;
}

/* Verbeterde kleuraanpassingen voor de sectiehoofdingen */
.why-us h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

/* Mobile heading adjustments */
@media screen and (max-width: 767px) {
    .why-us h2,
    .services h2,
    .regional-info h2,
    .contact h2 {
        font-size: 1.8rem;
        margin-bottom: 15px;
    }
}

/* Verschillende kleuren voor light en dark mode */
body.light .why-us h2,
body.light .services h2,
body.light .regional-info h2 {
    color: #5865f2; /* Originele blauwe kleur voor light mode */
}

body.dark .why-us h2,
body.dark .services h2,
body.dark .regional-info h2 {
    color: #7b86ff; /* Lichtere tint blauw voor dark mode voor betere leesbaarheid */
}

.reasons-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three items per row on larger screens */
    gap: 20px;
    justify-content: center; /* Center the grid items */
}

@media (max-width: 768px) {
    .reasons-grid {
        grid-template-columns: repeat(2, 1fr); /* Two items per row on medium screens */
        gap: 15px; /* Slightly smaller gap on mobile */
    }
}

@media (max-width: 480px) {
    .reasons-grid {
        grid-template-columns: 1fr; /* One item per row on small screens */
    }
}

/* Updated card styles with variation for dark mode */
.reason-card, .service-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    text-align: left;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s, color 0.3s;
    background: inherit;
    color: inherit;
    z-index: 1;
}

/* Mobile card adjustments */
@media screen and (max-width: 767px) {
    .reason-card, .service-card {
        padding: 15px;
    }
}

body.dark .reason-card,
body.dark .service-card {
    background: #32364a; /* Slightly lighter than body for contrast */
    color: #e8e8e8;
}

/* Verbeterde kleuraanpassingen voor card koppen */
.reason-card h3, .service-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

/* Mobile card heading adjustments */
@media screen and (max-width: 767px) {
    .reason-card h3, .service-card h3 {
        font-size: 1.1rem;
    }
}

body.light .reason-card h3,
body.light .service-card h3 {
    color: #5865f2; /* Originele blauwe kleur voor light mode */
}

body.dark .reason-card h3,
body.dark .service-card h3 {
    color: #8a94ff; /* Nog lichtere tint blauw voor dark mode koppen binnen cards */
}

/* Light Mode Specifics */
body.light .reason-card,
body.light .service-card,
body.light .contact-form {
    background: #ffffff;
    color: #333; /* Ensure dark text for readability */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.reason-card p, .service-card p {
    font-size: 1rem;
    color: inherit;
}

/* Services Section */
.services {
    text-align: center;
    margin-bottom: 40px;
}

.services h2, .why-us h2 {
    font-size: 2rem;
}

.service-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

/* Mobile service grid adjustment */
@media screen and (max-width: 767px) {
    .service-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 15px;
    }
}

/* Contact Section */
.contact {
    text-align: center;
    margin-bottom: 40px;
}

.contact h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

/* Verschillende kleuren voor light en dark mode */
body.light .contact h2 {
    color: #5865f2; /* Originele blauwe kleur voor light mode */
}

body.dark .contact h2 {
    color: #7b86ff; /* Lichtere tint blauw voor dark mode voor betere leesbaarheid */
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background: inherit;
    color: inherit;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
}

/* Mobile form adjustments */
@media screen and (max-width: 767px) {
    .contact-form {
        padding: 15px;
        gap: 15px;
        width: 95%;
        max-width: none;
    }
}

/* Dark mode form */
body.dark .contact-form {
    background: #32364a;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.form-group label {
    font-weight: bold;
    color: inherit;
}

.form-group input,
.form-group textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #333;
    background: inherit;
    color: inherit;
    font-size: 1rem;
}

/* Dark mode form fields */
body.dark .form-group input,
body.dark .form-group textarea {
    background: #3a3f54;
    border: 1px solid #4a4f64;
}

.form-group textarea {
    resize: none;
    height: 100px;
}

/* Radio Button Section */
.form-group-radio {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Radio Options Container */
.radio-options {
    display: flex; /* Align items in a row */
    flex-wrap: wrap; /* Allow wrapping if necessary */
    justify-content: center; /* Center the options */
    gap: 10px; /* Add spacing between options */
    margin-top: 5px;
}

.radio-title {
    font-weight: bold;
    color: inherit;
    margin-bottom: 5px;
}

/* General Radio Button Container Styles */
.radio-option {
    position: relative;
}

.radio-option input {
    display: none; /* Hide the native radio input */
}

/* Label Styles (Box) */
.radio-option label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    min-width: 120px; /* Consistent size */
    transition: all 0.3s ease; /* Smooth transitions */
}

/* Mobile radio button adjustments */
@media screen and (max-width: 767px) {
    .radio-option label {
        min-width: 90px;
        padding: 8px;
        font-size: 0.9rem;
    }
}

/* Updated radio buttons for dark mode */
body.dark .radio-option label {
    background-color: #3a3f54; /* Lighter than body background */
    color: white;
    border: 1px solid #4a4f64;
}

/* Light Mode Radio Button Box */
body.light .radio-option label {
    background-color: #f9f9f9; /* Light mode background */
    color: #333; /* Light mode text */
    border: 1px solid #ddd; /* Light mode border */
}

/* Hover Effect */
.radio-option label:hover {
    background-color: #404eed; /* Accent color for hover */
    color: white; /* Ensure readability */
    border-color: #5865f2; /* Accent border */
}

/* Selected (Checked) Styles */
.radio-option input:checked + label {
    background-color: #5865f2; /* Selected background color */
    color: white; /* High contrast text */
    font-weight: bold; /* Emphasize selection */
    border-color: #404eed; /* Accent border */
}


/* Footer */
footer {
    text-align: center;
    padding: 20px;
    width: 100%;
    margin-top: auto;
    background: transparent; /* Make footer background transparent */
    color: inherit;
    border-top: none; /* Remove border */
    position: relative;
    z-index: 10;
}

/* Theme toggle section in footer */
footer .theme-toggle-container {
    margin-top: 15px;
    position: relative;
    z-index: 20;
}

body.light footer {
    color: #333;
}

body.dark footer {
    color: #cccccc;
}

.graphic-container {
    text-align: center;
    margin: 20px 0;
}

.graphic-image {
    max-width: 80%;
    height: auto;
    margin: auto;
}

/* Mobile graphic adjustments */
@media screen and (max-width: 767px) {
    .graphic-image {
        max-width: 90%;
    }
}

/* Toggle Container */
.theme-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    gap: 10px; /* Space between icons and toggle */
    position: relative;
    z-index: 20;
    background: transparent;
}

/* Sun and Moon Icons */
.theme-toggle .icon {
    font-size: 20px; /* Adjust icon size */
    transition: opacity 0.3s ease, color 0.3s ease;
}

.theme-toggle .icon.sun {
    color: #f9d71c; /* Sun color */
    opacity: 1; /* Sun is visible by default */
    transition: opacity 0.3s ease;
}

.theme-toggle .icon.moon {
    color: #5865f2; /* Moon color */
    opacity: 100; /* Moon is hidden by default */
    transition: opacity 0.3s ease;
}

/* Visibility Change on Toggle */
.theme-toggle input:checked ~ .icon.sun {
    opacity: 0; /* Hide sun */
}

.theme-toggle input:checked ~ .icon.moon {
    opacity: 1; /* Show moon */
}

/* Toggle Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    z-index: 25;
}

/* Hide the Default Checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Slider for the Switch */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; /* Default background color */
    border-radius: 30px; /* Fully round slider */
    transition: background-color 0.3s ease;
}

/* Dark Mode Styling */
body.dark .slider {
    background-color: #3a3f54; /* Matches other dark UI elements */
}

/* Circular Knob */
.slider:before {
    position: absolute;
    content: "";
    height: 24px; /* Knob height */
    width: 24px; /* Knob width */
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    top: 3px;
    left: 3px; /* Starting position on the left */
    transition: transform 0.3s ease;
}

/* Active State (Checked) */
input:checked + .slider {
    background-color: #5865f2; /* Accent color for active state */
}

input:checked + .slider:before {
    transform: translateX(30px); /* Move knob to the right */
}

.error-message {
    color: #ff5252;
    font-size: 0.85rem;
    margin-top: 5px;
    margin-left: 3px;
    display: block;
    animation: fadeIn 0.3s ease;
}

/* Mobile error message adjustments */
@media screen and (max-width: 767px) {
    .error-message {
        font-size: 0.8rem;
    }
}

/* Regional Info Section */
.regional-info {
    text-align: center;
    margin-bottom: 40px;
}

.regional-info h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.regional-info p {
    margin-bottom: 20px;
}

.regional-info-extra {
    max-width: 800px;
    margin: 40px auto 0;
    padding: 20px;
    border-radius: 10px;
    background: inherit;
    color: inherit;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
}

/* Mobile regional info adjustments */
@media screen and (max-width: 767px) {
    .regional-info-extra {
        padding: 15px;
        width: 95%;
    }
}

body.dark .regional-info-extra {
    background: #32364a;
}

body.light .regional-info-extra {
    background: #ffffff;
    color: #333;
}

/* Verbeterde kleuraanpassingen voor regionale info kopjes */
.regional-info-extra h3 {
    margin-bottom: 15px;
}

body.light .regional-info-extra h3 {
    color: #5865f2; /* Originele blauwe kleur voor light mode */
}

body.dark .regional-info-extra h3 {
    color: #8a94ff; /* Lichtere tint blauw voor dark mode */
}

.service-list {
    list-style-type: none;
    padding-left: 5px;
    margin-top: 10px;
}

.service-list li {
    margin-bottom: 8px;
    padding-left: 20px;
    position: relative;
}

.service-list li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #5865f2;
}

body.dark .service-list li:before {
    color: #8a94ff; /* Lichtere tint blauw voor checkmarks in dark mode */
}

/* Verbeterde hoek-popup stijlen */
.corner-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    z-index: 1000;
    animation: slideIn 0.5s ease forwards;
}

/* Mobile popup adjustments */
@media screen and (max-width: 767px) {
    .corner-popup {
        width: 85%;
        max-width: 280px;
        right: 15px;
        bottom: 15px;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.popup-content {
    background: inherit;
    color: inherit;
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    padding: 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

/* Mobile popup content adjustments */
@media screen and (max-width: 767px) {
    .popup-content {
        padding: 15px;
    }
}

body.dark .popup-content {
    background: #32364a;
}

body.light .popup-content {
    background: #ffffff;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.popup-close-btn {
    background: none;
    border: none;
    color: inherit;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    opacity: 0.7;
    transition: opacity 0.2s ease;
}

.popup-close-btn:hover {
    opacity: 1;
}

.timer-container {
    width: 36px;
    height: 36px;
}

.timer-svg {
    width: 100%;
    height: 100%;
}

.timer-bg {
    stroke: #e0e0e0;
}

body.dark .timer-bg {
    stroke: #4a4f64;
}

.timer-progress {
    stroke: #5865f2;
    transform-origin: center;
    transform: rotate(-90deg);
    transition: stroke-dashoffset 1s linear;
}

.timer-text {
    font-size: 14px;
    font-weight: bold;
}

body.light .timer-text {
    fill: #5865f2;
}

body.dark .timer-text {
    fill: #8a94ff; /* Lichtere tint blauw voor timer tekst in dark mode */
}

.popup-icon-success {
    font-size: 40px;
    margin-bottom: 10px;
    display: block;
}

body.light .popup-icon-success {
    color: #5865f2;
}

body.dark .popup-icon-success {
    color: #8a94ff; /* Lichtere tint blauw voor success icon in dark mode */
}

.popup-content h3 {
    margin: 0 0 10px;
}

body.light .popup-content h3 {
    color: #5865f2;
}

body.dark .popup-content h3 {
    color: #8a94ff; /* Lichtere tint blauw voor popup koppen in dark mode */
}

.popup-content p {
    margin: 0 0 5px;
}

.popup-detail {
    font-size: 0.9rem;
    opacity: 0.8;
}

/* Mobile popup text adjustments */
@media screen and (max-width: 767px) {
    .popup-detail {
        font-size: 0.85rem;
    }

    .popup-icon-success {
        font-size: 36px;
    }
}

.popup-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #5865f2, #7289da);
}

/* Verbeterde form styling */
.cta-button.submitting {
    background: #8590f2;
    cursor: not-allowed;
    position: relative;
    overflow: hidden;
}

.cta-button.submitting:after {
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 50%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}

/* Provincie en diensten weergave */
.provinces-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 30px 0;
}

@media (min-width: 768px) {
    .provinces-container {
        flex-direction: row;
        align-items: stretch;
    }
}

.province-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: inherit;
    color: inherit;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
}

body.dark .province-block {
    background: #32364a;
}

body.light .province-block {
    background: #ffffff;
}

.province-emblem {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

body.dark .province-emblem {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.province-logo {
    height: 80px;
    width: auto;
    max-width: 100px;
    display: block;
    margin: 0 auto;
}

/* Mobile logo adjustments */
@media screen and (max-width: 767px) {
    .province-logo {
        height: 70px;
    }

    .province-emblem {
        padding: 15px;
    }
}

.province-info {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* Mobile province info adjustments */
@media screen and (max-width: 767px) {
    .province-info {
        padding: 15px;
    }
}

.province-info h3 {
    margin-bottom: 10px;
    text-align: center;
}

body.light .province-info h3 {
    color: #5865f2;
}

body.dark .province-info h3 {
    color: #8a94ff; /* Lichtere tint blauw voor provincie koppen in dark mode */
}

.province-info p {
    margin-bottom: 15px;
    text-align: center;
}

.province-cities {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    margin-top: auto;
}

.city-tag {
    font-size: 0.85rem;
    padding: 4px 10px;
    border-radius: 15px;
    display: inline-block;
}

/* Mobile city tag adjustments */
@media screen and (max-width: 767px) {
    .city-tag {
        font-size: 0.8rem;
        padding: 3px 8px;
    }

    .province-cities {
        gap: 6px;
    }
}

body.light .city-tag {
    background: #f0f2ff;
    color: #5865f2;
}

body.dark .city-tag {
    background: #3a3f54;
    color: #8a94ff; /* Lichtere tint blauw voor stadslabels in dark mode */
}

.highlighted-city {
    margin: 30px auto;
    max-width: 600px;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background: inherit;
    color: inherit;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
}

/* Mobile highlighted city adjustments */
@media screen and (max-width: 767px) {
    .highlighted-city {
        padding: 15px;
        width: 95%;
        margin: 20px auto;
    }
}

body.light .highlighted-city {
    background: #ffffff;
    border: 2px solid #5865f2;
}

body.dark .highlighted-city {
    background: #32364a;
    border: 2px solid #7b86ff; /* Lichtere tint blauw voor border in dark mode */
}

.city-icon {
    font-size: 2.5rem;
    margin-right: 20px;
}

.city-icon img {
    display: block;
    width: 50px;
    height: 50px;
}

/* Mobile city icon adjustments */
@media screen and (max-width: 767px) {
    .city-icon {
        font-size: 2rem;
        margin-right: 15px;
    }

    .city-icon img {
        width: 40px;
        height: 40px;
    }
}

.city-info h3 {
    margin-bottom: 5px;
}

body.light .city-info h3 {
    color: #5865f2;
}

body.dark .city-info h3 {
    color: #8a94ff; /* Lichtere tint blauw voor stadsinformatie koppen in dark mode */
}

.services-overview {
    margin: 40px 0;
    padding: 20px;
    border-radius: 10px;
    background: inherit;
    color: inherit;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
}

/* Mobile services overview adjustments */
@media screen and (max-width: 767px) {
    .services-overview {
        padding: 15px;
        margin: 30px 0;
    }
}

body.dark .services-overview {
    background: #32364a;
}

body.light .services-overview {
    background: #ffffff;
}

.services-overview h3 {
    text-align: center;
    margin-bottom: 20px;
}

body.light .services-overview h3 {
    color: #5865f2;
}

body.dark .services-overview h3 {
    color: #8a94ff; /* Lichtere tint blauw voor service overzicht koppen in dark mode */
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
}

@media (min-width: 576px) {
    .services-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 992px) {
    .services-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.service-item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease, background-color 0.3s ease;
    background: inherit;
}

/* Mobile service item adjustments */
@media screen and (max-width: 767px) {
    .service-item {
        padding: 12px;
    }
}

body.dark .service-item {
    background: #32364a;
}

body.light .service-item {
    background: #ffffff;
}

.service-icon {
    font-size: 1.5rem;
    margin-right: 12px;
    flex-shrink: 0;
}

/* Mobile service icon adjustments */
@media screen and (max-width: 767px) {
    .service-icon {
        font-size: 1.3rem;
        margin-right: 10px;
    }
}

/* Algemene Card Hover Effects alleen voor desktop */
@media (hover: hover) and (pointer: fine) {
    /* Blauwe gloed effect bij hover */
    .reason-card::before,
    .service-card::before,
    .province-block::before,
    .highlighted-city::before,
    .services-overview::before,
    .regional-info-extra::before,
    .contact-form::before,
    .service-item::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(circle at center, rgba(88, 101, 242, 0), rgba(88, 101, 242, 0));
        opacity: 0;
        z-index: -1;
        transition: opacity 0.5s ease, background 0.5s ease;
        pointer-events: none;
    }

    /* Geleidelijke verschijning van de blauwe gloed bij hover */
    .reason-card:hover::before,
    .service-card:hover::before,
    .province-block:hover::before,
    .highlighted-city:hover::before,
    .services-overview:hover::before,
    .regional-info-extra:hover::before,
    .contact-form:hover::before,
    .service-item:hover::before {
        background: radial-gradient(circle at center, rgba(88, 101, 242, 0.1), rgba(88, 101, 242, 0));
        opacity: 1;
    }

    /* Subtiele OMHOOG beweging bij hover (in plaats van zijwaarts) */
    .reason-card:hover,
    .service-card:hover,
    .province-block:hover,
    .highlighted-city:hover,
    .services-overview:hover,
    .regional-info-extra:hover,
    .contact-form:hover,
    .service-item:hover {
        transform: translateY(-5px);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
    }
}

/* Verwijder oude hover animaties */
.reason-card:hover::after,
.service-card:hover::after {
    content: none;
}